import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
import RadioCheckCircle from '@/components/RadioCheck/RadioCheckCircle/RadioCheckCircle.vue';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

const HIGH_IMAGE_SIZE = 2000;
const RATIO_IMAGE_DIVIDE = 2;

export default {
	name: 'Modal',
	components: { HxPdfExporter, RadioCheckCircle },
	props: {
		pdfData: {
			type: Object,
		},
		templateName: {
			type: String,
		},
		imagePreviewModal: {
			type: String,
			default: 'preview-single-img-modal',
		},
		imageUrl: {
			type: String,
		},
		topText: {
			type: String,
		},
		bottomText: {
			type: String,
		},

		filePreviewModalId: {
			type: String,
		},
		pdfPreviewer: {
			type: Object,
			default() {
				return {};
			},
		},
		isDownloadAvailable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			modalPdfData: {},
			modalTemplateName: '',
			imageBackground: 'white',
			numPages: undefined,
			isDownloadButtonShow: false,
			isFileNameShow: false,
			isShowPreviewingImage: false,
		};
	},
	computed: {
		previewImgBacground() {
			return 'preview-img-content-container--' + this.imageBackground;
		},
	},

	watch: {
		pdfData: {
			handler: function (pdfData) {
				this.modalPdfData = { ...pdfData };
			},
			deep: true,
		},
	},
	methods: {
		onLoadPrevewingImage() {
			this.isShowPreviewingImage = true;
			this.$store.commit('setIsAppProcessing', false);
		},

		onPreviewingImageShow() {
			console.log('onPreviewingImageShow');
			this.$store.commit('setIsAppProcessing', true);
		},

		onPreviewingImageHidden() {
			this.isShowPreviewingImage = false;
			this.imageBackground = 'white';
		},

		onDownload() {
			this.$emit('on-download-pdf');
		},

		onPreviewingPdfShow() {
			console.log('onPreviewingPdfShow');
			if (this.pdfPreviewer.url) {
				setTimeout(() => {
					this.$store.commit('setIsAppProcessing', true);
					this.getPdfJs(this.pdfPreviewer.url);
				}, 0);
			}
		},

		onPreviewingPdfHidden() {
			this.numPages = undefined;
			this.isDownloadButtonShow = false;
			this.isFileNameShow = false;
		},

		onChangeBackgroundColor(color) {
			console.log({ color });
			this.imageBackground = color;
		},

		onClose() {
			this.$bvModal.hide(this.imagePreviewModal);
		},

		/**
		 * Get Page Orientation
		 * @param {Object} page page object
		 * @returns Orientation of page
		 */
		getPageOrientation(page) {
			const defaultScale = 1;
			const viewport = page.getViewport({ scale: defaultScale });
			return viewport.width > viewport.height ? 'landscape' : 'portrait';
		},
		calcImageScale(width, height) {
			const imageSize = {
				width: 1420,
				height: 706,
			};
			const defaultSize = {
				width: 1920,
				height: 1080,
			};
			const screenSize = {
				width: window.innerWidth,
				height: window.innerHeight,
			};

			// Calculate scale depend on Image size
			if (width > HIGH_IMAGE_SIZE) {
				width = width / RATIO_IMAGE_DIVIDE;
				height = height / RATIO_IMAGE_DIVIDE;
			}
			let scale = {
				width: width / imageSize.width,
				height: height / imageSize.height,
			};

			// Calculate scale depend on screenWidth
			if (screenSize.width < screenSize.height) {
				if (screenSize.width < width) {
					scale.width = (scale.width * screenSize.width) / width;
					scale.height = (scale.height * screenSize.width) / width;
				}
			} else {
				scale.width = (scale.width * screenSize.width) / defaultSize.width;
				scale.height = (scale.height * screenSize.height) / defaultSize.height;
			}

			return scale;
		},
		getPdfJs(pdfUrl) {
			this.numPages = 1;
			try {
				let url = pdfUrl;
				let pdfDoc = null;

				// Render the page
				const renderPage = (numPages) => {
					for (let num = 1; num <= numPages; num++) {
						pdfDoc.getPage(num).then((page) => {
							const canvas = document.querySelector(`#pdf-render` + num);

							const ctx = canvas.getContext('2d');
							// Set scale
							const scale = 1.4;

							const viewport = page.getViewport({ scale });
							canvas.height = viewport.height;
							canvas.width = viewport.width;

							const renderCtx = {
								canvasContext: ctx,
								viewport,
							};

							page.render(renderCtx).promise.then(() => {
								console.log('rendered');
							});
						});
					}
				};

				// Get Document
				pdfjsLib
					.getDocument(url)
					.promise.then((pdfDoc_) => {
						pdfDoc = pdfDoc_;
						this.numPages = pdfDoc.numPages;

						renderPage(this.numPages);

						this.isDownloadButtonShow = true;
						this.isFileNameShow = true;

						this.$store.commit('setIsAppProcessing', false);
					})
					.catch(async () => {
						let res = await fetch(pdfUrl);
						let blob = await res.blob();
						let image = new Image();
						image.onload = () => {
							let canvas = document.querySelector(`#pdf-render1`);
							let imgscale = this.calcImageScale(
								image.naturalWidth,
								image.naturalHeight
							);
							canvas.width = 1420 * imgscale.width;
							canvas.height = 830 * imgscale.height;
							let ctx = canvas.getContext('2d');
							ctx.fillStyle = 'white';
							ctx.fillRect(0, 0, canvas.width, canvas.height);
							ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

							this.isDownloadButtonShow = true;
							this.isFileNameShow = true;
						};
						image.src = URL.createObjectURL(blob);

						this.$store.commit('setIsAppProcessing', false);
					});
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		console.log('ON MODAL MOUNT');
		this.modalTemplateName = this.templateName;
	},
};
