export default {
	name: 'CustomRadioCheck',
	props: {
		checked: Boolean,
		label: String,
		disabled: Boolean,
		radioValue: String,
		radioName: String,
		color: {
			type: String,
			default: 'white',
		},
	},
	computed: {
		radioCheckmarkClass() {
			return 'radioCheckmark--' + this.color;
		},
	},
};
